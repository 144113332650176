<template>
	<div data-route>
		<error />
	</div>
</template>

<script>

	import Error from '@/components/ui/Error';

	export default {
		metaInfo: {
			title: 'Error'
		},
		components: {
			Error
		}
	};

</script>

<style lang="scss" scoped>
	[data-route] {
		padding-top:rem(48);
	}
</style>
