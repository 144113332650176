<template>
	<div data-component="error">
		<page-header
			heading="Page not found"
		/>
		<div data-element="main">
			<p>This page can't be found.</p>
			<btn
				size="xl"
				route="/"
				text="Go back to the home page"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import Btn         from '@/components/ui/Btn';

	export default {
		components: {
			PageHeader,
			Btn
		},
		data: () => ({
		}),
		computed: {
		},
		created () {
			this.$store.commit('ui/setLoadingIsHidden');
		},
		methods: {
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='error'] {
		[data-element='main'] {
			p {
				font-size:rem(18);
				margin:0 0 rem(32);
			}
			[data-component='btn'] {
				float:left;
				width:auto;
				margin:0 auto 0 0;
			}
		}
	}
</style>
